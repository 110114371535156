//@ USER LOGIN
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

//@ USER REGISTER
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

//@ POST REVIEW
export const POST_REVIEW_REQUEST = "POST_REVIEW_REQUEST";
export const POST_REVIEW_SUCCESS = "POST_REVIEW_SUCCESS";
export const POST_REVIEW_FAIL = "POST_REVIEW_FAIL";

//@ GET REVIEW
export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAIL = "GET_REVIEW_FAIL";

//@ GET CONTACT
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL";

//@ GET PORTFOLIO
export const GET_PORTFOLIO_REQUEST = "GET_PORTFOLIO_REQUEST";
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_FAIL = "GET_PORTFOLIO_FAIL";

//@ GET TRENDINGS
export const GET_TRENDING_REQUEST = "GET_TRENDING_REQUEST";
export const GET_TRENDING_SUCCESS = "GET_TRENDING_SUCCESS";
export const GET_TRENDING_FAIL = "GET_TRENDING_FAIL";

//@ GET BACKGROUND
export const GET_BACKGROUND_REQUEST = "GET_BACKGROUND_REQUEST";
export const GET_BACKGROUND_SUCCESS = "GET_BACKGROUND_SUCCESS";
export const GET_BACKGROUND_FAIL = "GET_BACKGROUND_FAIL";

//@ GET INFO
export const GET_INFO_REQUEST = "GET_INFO_REQUEST";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAIL = "GET_INFO_FAIL";
